<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_24_6)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M128.003 30.6338C99.7765 30.6338 72.0385 32.6989 44.9309 36.6847C31.5789 38.6479 21.6338 50.5077 21.6338 64.7748L21.6338 137.112C21.6338 151.379 31.5789 163.239 44.9308 165.202L43.3573 175.904L44.9308 165.202C57.7394 167.086 70.6884 168.54 83.7631 169.551C89.397 169.986 93.7465 174.685 93.7465 180.335V210.041L123.237 180.55C129.22 174.567 137.232 171.24 145.52 170.988C167.718 170.313 189.591 168.362 211.069 165.204C224.421 163.241 234.366 151.381 234.366 137.114L234.366 64.7738C234.366 50.5066 224.421 38.6467 211.069 36.6836C183.963 32.6986 156.228 30.6338 128.003 30.6338ZM41.7838 15.281C69.9301 11.1425 98.7199 9 128.003 9C157.284 9 186.072 11.1421 214.216 15.2799C238.941 18.915 256 40.5546 256 64.7738L256 137.114C256 161.333 238.941 182.973 214.215 186.608C191.915 189.886 169.211 191.912 146.177 192.612C143.225 192.701 140.501 193.881 138.534 195.848L90.5783 243.804C87.4847 246.897 82.8321 247.823 78.7901 246.148C74.7481 244.474 72.1127 240.53 72.1127 236.155L72.1127 190.264C61.9257 189.302 51.8138 188.081 41.7837 186.606C17.059 182.971 0 161.331 0 137.112L0 64.7748C0 40.5559 17.0591 18.9164 41.7838 15.281Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
