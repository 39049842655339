<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M84.3126 79.8446L9.97825 90.6219L8.66167 90.8899C6.66862 91.419 4.85169 92.4676 3.39643 93.9285C1.94117 95.3895 0.899722 97.2105 0.378434 99.2056C-0.142854 101.201 -0.125302 103.298 0.429296 105.285C0.983895 107.271 2.05567 109.074 3.53517 110.51L57.3868 162.929L44.687 236.972L44.5356 238.254C44.4136 240.315 44.8416 242.372 45.7758 244.213C46.7101 246.055 48.1169 247.615 49.8524 248.734C51.5878 249.853 53.5895 250.491 55.6525 250.582C57.7154 250.673 59.7655 250.214 61.5928 249.252L128.074 214.299L194.404 249.252L195.569 249.788C197.493 250.546 199.583 250.778 201.625 250.461C203.668 250.144 205.589 249.29 207.192 247.985C208.796 246.681 210.023 244.973 210.749 243.038C211.475 241.103 211.672 239.009 211.322 236.972L198.61 162.929L252.485 110.499L253.394 109.508C254.692 107.91 255.543 105.995 255.861 103.96C256.178 101.925 255.951 99.8424 255.201 97.924C254.451 96.0057 253.207 94.3204 251.594 93.0397C249.981 91.759 248.057 90.9288 246.019 90.6336L171.685 79.8446L138.455 12.501C137.494 10.5498 136.005 8.90678 134.158 7.75785C132.311 6.60893 130.18 6 128.004 6C125.829 6 123.697 6.60893 121.85 7.75785C120.003 8.90678 118.515 10.5498 117.553 12.501L84.3126 79.8446Z"
      fill="currentColor"
    />
  </svg>
</template>
