import delve from 'dlv'
import {getFullUrl} from "~/utils/url.js";

export function setSeo(seo, title, defaultImageUrl= null) {
  const defaultImageUrlResult = defaultImageUrl && isImageUrl(defaultImageUrl) ? defaultImageUrl : getFullUrl('/logo.png');
  const { locale } = useI18n()
  const metaTitle = delve(seo, 'metaTitle', title)
  const metaDescription = delve(seo, 'metaDescription')
  const metaImage = delve(seo, 'metaImage')
  const metaImageUrl = metaImage.data ? useStrapiMedia(delve(metaImage, 'data.attributes.url')) : defaultImageUrlResult
  const metaImageAlt = metaImage.data ? delve(metaImage, 'data.attributes.alternativeText') : null
  const metaRobots = delve(seo, 'robots')
  const metaKeywords = delve(seo, 'keywords')

  useSeoMeta({
    title: metaTitle,
    twitterTitle: metaTitle,
    ogTitle: metaTitle,
    ogUrl: getFullUrl(),
    ogSiteName: 'Planète Croisière',
    description: metaDescription,
    twitterDescription: metaDescription,
    ogDescription: metaDescription,
    twitterImage: metaImageUrl,
    ogImage: metaImageUrl,
    ogImageAlt: metaImageAlt,
    ogVideo: delve(seo, 'metaVideo'),
    ogType: 'article',
    ogLocale: locale.value === 'fr' ? 'fr_FR' : 'en_US',
    twitterImageAlt: metaImageAlt,
    robots: metaRobots,
    keywords: metaKeywords,
    twitterCard: 'summary',
  })

  return seo
}
