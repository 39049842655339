<script setup lang="ts">
</script>

<template>
  <div class="wave-title">
    <slot />
    <UiIconsWave class="wave-icon" />
  </div>
</template>

<style lang="scss">
.wave-title {
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    margin: 0;
  }

  .wave-icon {
    margin-top: .15em;
    color: var(--color-sand-300);
    font-size: 10em;
  }
}
</style>
