<script lang="ts" setup>
import type { CollapseModel } from '~/models/CollapseModel.js'

defineProps({
  elements: {
    type: Array<CollapseModel>,
  },
})

const currentCollapse = ref<number>()
</script>

<template>
  <ul class="accordion">
    <li
      v-for="({ title, description }, index) in elements"
      :key="index"
      @click="currentCollapse === index ? currentCollapse = null : currentCollapse = index"
    >
      <button class="accordion--title title title--secondary">
        {{ title }}<UiIconsArrow :direction="currentCollapse === index ? 'up' : 'down'" />
      </button>
      <TransitionExpand>
        <div
          v-show="currentCollapse === index"
          class="accordion--content"
          v-html="description"
        />
      </TransitionExpand>
    </li>
  </ul>
</template>

<style lang="scss">
ul.accordion {
  max-width: 850px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0;
  list-style: none;
  text-align: left;

  > li {
    background: var(--color-primary-white);
    box-shadow: 0px 3px 8px 0px #000E5540;

    button.accordion--title {
      color: inherit;
      cursor: pointer;
      background: none;
      border: none;
      width: 100%;
      display: flex;
      align-items: center;
      text-align: left;
      justify-content: space-between;
      margin: 0;
      padding: 1.2em 1.5rem;

      @media (min-width: map-get($grid-breakpoints, md)) {
        font-size: 1.25em;
        padding: 1.2em 1.75rem;
      }
    }

    .accordion--content {
      padding: 0 1.5rem;

      @media (min-width: map-get($grid-breakpoints, md)) {
        font-size: 1.1em;
        padding: 0 1.75rem;
      }
    }
  }
}
</style>
